<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <!-- 1.Choose company -->
    <v-card>
      <v-card-title>Notification</v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="3" sm="3">
            <v-select
              :items="ay_list"
              label="Select Academic Year"
              v-model="savedata.ay"
              item-text="ay"
              item-value="id"
              required
            >
            </v-select>
          </v-col>
          <v-col md="3" sm="3">
            <v-select
              :items="sem_list"
              label="Select semester"
              v-model="savedata.sem"
              item-text="sem"
              item-value="id"
              required
            ></v-select>
          </v-col>
          <v-col md="3" sm="3">
            <v-select
              :items="ptype_list"
              label="Select Program Type"
              v-model="savedata.ptype"
              item-text="name"
              item-value="id"
              required
              @change="getprogramtype"
            ></v-select>
          </v-col>
          <template v-if="p_List != null">
            <v-col md="3" sm="3">
              <v-select
                :items="p_List"
                label="Select Program"
                v-model="savedata.prog"
                item-text="program"
                item-value="id"
                required
                @change="getprogyear"
              ></v-select>
            </v-col>
          </template>
        </v-row>

        <template v-if="year != null">
          <v-row>
            <v-col md="3" sm="3">
              <v-select
                :items="year"
                label="Select Year"
                v-model="savedata.year"
                item-text="name"
                item-value="id"
                required
                @change="getprogyear"
              ></v-select>
            </v-col>
            <v-col md="3" sm="3">
              <v-btn color="primary" dark class="mb-2" @click="addcriteria">
                <v-icon dark>mdi-plus</v-icon>Proceed to Send Notification
              </v-btn>
            </v-col>
            <v-col md="3" sm="3">
              <v-btn color="primary" dark class="mb-2">
                <v-icon dark>mdi-plus</v-icon>Notification History
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
    <v-card v-if="temp != null">
      <v-card-text>
        <v-row>
          <v-col md="3" sm="3">
            <template v-if="utype != null">
              <v-select
                :items="utype"
                label="Select Usertype"
                v-model="savedata.utype"
                item-text="utype"
                item-value="id"
                required
                @change="seltemp"
              ></v-select>
            </template>
          </v-col>
          <v-col md="3" sm="3">
            <template v-if="com_list != null">
              <v-select
                :items="com_list"
                label="Select Communication Mode"
                v-model="savedata.cmode"
                item-text="mode"
                item-value="id"
                required
                @change="seltemp"
              ></v-select>
            </template>
          </v-col> 
          <v-col md="3" sm="3">
            <template v-if="noti_List != null">
              <v-select
                :items="noti_List"
                label="Select Notification Template"
                v-model="savedata.temp"
                item-text="templatename"
                item-value="id"
                required
                @change="viewtemp"
              ></v-select>
            </template>
          </v-col>
          </v-row>
        <template v-if="viewtmp!=null">
          <v-row>
          <v-col cols="6" sm="6" md="3">
          <v-text-field
            label="Subject"
            outlined
            v-model="savedata.viewsubject"
          ></v-text-field>
        </v-col>
         <v-col cols="6" md="6">
        <v-textarea
          outlined
          label="Body"
          v-model="savedata.viewbody"
         ></v-textarea>
      </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
            <span v-bind:style="{ color: red, fontSize: 15 + 'px' }">Note:For SMS;Please Start with any of the following test message body(use %n for new line in SMS)<br/>
            Dear Student%n<br/>
            Dear Staff%n</span>
            </v-col>
            </v-row>
            <v-row justify="center">
                <v-btn color="primary" dark class="mb-2" @click="notify" >
                    <v-icon dark>mdi-plus</v-icon>Notify
                </v-btn>
            </v-row>
        </template>
          </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    snackbar_msg: "",
    snackbar: false,
    color: "",
    savedata: {
      ay: null,
      sem: null,
      ptype: null,
      year: null,
      utype: null,
      cmode: null,
      temp:null,
      viewsubject:null,
      viewbody:null,
    },
    //
    ptype_list: null,
    ay_list: null,
    sem_list: null,
    p_List: null,
    year: null,
    temp: null,
    company: null,
    utype: null,
    com_list: null,
    noti_List:null,
    viewtmp:null,
    viewsubject:null,
    viewbody:null,
    notification: null,
  }),
  watch: {},
  methods: {
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    getEliPdf() {},
    getprogramtype() {
      //alert(this.savedata.ptype);
      let formData = new FormData();
      formData.append("ptid", this.savedata.ptype);
      axios
        .post("/TPONotification/getprogramtype", formData)
        .then((res) => {
          //console.log("response");
          //console.log(res.data);
          if (res.data.msg == "200") {
            //4CAF50
            // this.company_list = res.data.company_list
            this.p_List = res.data.program_List;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })
        .finally(() => {});
    },
    getprogyear() {
      //    alert(this.savedata.prog);
      let formData = new FormData();
      
      formData.append("pid", this.savedata.prog);
      formData.append("ptid", this.savedata.ptype);
      axios
        .post("/TPONotification/getprogyear", formData)
        .then((res) => {
          //console.log("response");
          //console.log(res.data);
          if (res.data.msg == "200") {
            //4CAF50
            // this.company_list = res.data.company_list
            this.year = res.data.Year;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })
        .finally(() => {});
    },
    addcriteria() {
      if (this.savedata.ay == null) {
        this.showSnackbar("#b71c1c", "Please Select Academic Year..");
      } else if (this.savedata.sem == null) {
        this.showSnackbar("#b71c1c", "Please Select Semester..");
      } else if (this.savedata.year == null) {
        this.showSnackbar("#b71c1c", "Please Select Year...");
        this.temp = null;
      } else {
        this.temp = "open";
        axios
          .post("/TPONotification/gettemp")
          .then((res) => {
            //console.log("response");
            //console.log(res.data);
            if (res.data.msg == "200") {
              this.utype = res.data.utype;
              this.com_list = res.data.com_list;
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          })
          .finally(() => {});
      } 
    },
    seltemp() {
      //alert(this.savedata.ptype);
      let formData = new FormData();
      formData.append("cid", this.savedata.cmode);
      formData.append("utype", this.savedata.utype);
      axios
        .post("/TPONotification/seltemp", formData)
        .then((res) => {
          //console.log("response");
          //console.log(res.data);
          if (res.data.msg == "200") {
            //4CAF50
            
            // this.company_list = res.data.company_list
            this.noti_List = res.data.notifictemp;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })
        .finally(() => {});
    },
    viewtemp() {
      //alert(this.savedata.ptype);
      let formData = new FormData();
      formData.append("cid", this.savedata.cmode);
      formData.append("utype", this.savedata.utype);
       formData.append("temp", this.savedata.temp);
      axios
        .post("/TPONotification/viewtemp", formData)
        .then((res) => {
          //console.log("response");
          //console.log(res.data);
          if (res.data.msg == "200") {
            //4CAF50
            
            // this.company_list = res.data.company_list
            this.viewtmp = res.data.vid;
             this.savedata.viewsubject = res.data.vsubject;
              this.savedata.viewbody = res.data.vbody;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })
        .finally(() => {});
    },
    notify()
    {
alert();
    },
  },
  mounted() {
    axios
      .post("/TPONotification/notification")
      .then((res) => {
        //console.log("response");
        //console.log(res.data);
        if (res.data.msg == "200") {
          //4CAF50
          this.ptype_list = res.data.ptype_list;
          this.ay_list = res.data.ay_list;
          this.sem_list = res.data.sem_list;
        }
      })
      .catch((error) => {
        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
      })
      .finally(() => {});
  },
};
</script>
